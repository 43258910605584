module.exports = [
  {
    slug: "ui-designer",
    closed: true,
    area: "progettazione",
    role: "Junior UI Designer",
    // roleH1: "UX_&_UI Designer",
    // subtitle: "(mind reader)",
    ctaMessage: "Ami progettare?",
    ctaBtn: "Contattaci!",
    competences: [
      {
        title: "Competenze richieste",
        skills: ["Conoscenze base di UI Design", "Utilizzo di Figma"],
      },
      {
        title: "Impareremo insieme",
        skills: ["Icon design", "Illustrazione digitale", "Design system"],
      },
    ],
    bodyInfo: {
      title: "Di cosa ti occuperai come UI designer?",
      icon: "pencil",
      items: [
        "Progetterai interfacce di app e piattaforme web, avendo come primo obiettivo la migliore user experience per gli utenti",
        "Collaborerai con il team di sviluppo, in modo da rendere fluido il passaggio dalla progettazione alla realizzazione",
        "Creerai prototipi interattivi delle tue interfacce, testandoli con utenti e clienti",
        "Gestirai il Design System di Mabiloft, che verrà utilizzato come base per i progetti",
        "Farai tanta ricerca!",
      ],
    },
  },
  {
    slug: "web-developer",
    area: "sviluppo",
    role: "Frontend Developer - React",
    // role: "Junior Web Developer",
    ctaMessage: "Ami programmare?",
    ctaBtn: "Contattaci!",
    competences: [
      {
        title: "Impareremo insieme",
        skills: ["React.js", "Typescript"],
      },
      {
        title: "Magari conosci già",
        skills: ["Material Design", "JSS"],
      },
    ],
    bodyInfo: {
      title: "Di cosa ti occuperai come Frontend Developer?",
      icon: "laptop",
      items: [
        "Imparerai a costruire un progetto digitale in ogni sua parte",
        "Svilupperai componenti per migliorare l'esperienza di interazione dell'utente",
        "Dividerai la business logic dalla parte presentazionale",
        "Scriverai buon codice in react+typescript",
        "Creerai i prodotti digitali del futuro",
        "Farai tanta ricerca!",
      ],
    },
  },
  {
    slug: "mobile-developer",
    // closed: true,
    area: "sviluppo",
    role: "Mobile App Developer - Flutter",
    roleH1: "Mobile_App Developer - Flutter",
    // role: "iOS Developer",
    // roleH1: "iOS Developer",
    // imageSEO: require("./images/cover_mobile_developer.png"),
    ctaMessage: "Ami programmare?",
    // ctaBtn: "Contattaci!",
    ctaBtn: "Candidati ora!",
    // developDescription: "applicazioni mobile per iOS e Android",
    moreInfo:
      "Valutiamo anche frontend developer appassionati da far crescere nello sviluppo di applicazioni mobile",
    competences: [
      {
        title: "Cresceremo insieme su",
        skills: ["Flutter + Dart"],
      },
      {
        title: "Magari conosci anche",
        skills: ["Android + Kotlin/Java", "iOS + Swift", "React-Native + JS"],
      },
    ],
    bodyInfo: {
      title: "Di cosa ti occuperai come Mobile App Developer?",
      icon: "phone",
      items: [
        "Imparerai a costruire un progetto digitale in ogni sua parte",
        "Svilupperai componenti per migliorare l'esperienza di interazione dell'utente",
        "Dividerai la business logic dalla parte presentazionale",
        "Scriverai buon codice organizzato",
        "Creerai i prodotti digitali del futuro",
        "Farai tanta ricerca!",
      ],
    },
  },
  {
    slug: "full-stack-developer",
    closed: true,
    area: "sviluppo",
    role: "Full-stack Developer",
    ctaMessage: "Ami programmare?",
    ctaBtn: "Contattaci!",
    competences: [
      {
        title: "Competenze richieste",
        skills: ["React", "NodeJS", "Database NoSQL"],
      },
      {
        title: "Magari conosci anche",
        skills: ["Mongo", "PostgreSQL"],
      },
    ],
  },
  {
    slug: "front-end-developer",
    closed: true,
    area: "sviluppo",
    role: "Front-end Developer",
    ctaMessage: "Ami programmare?",
    ctaBtn: "Contattaci!",
    competences: [
      {
        title: "Competenze richieste",
        skills: ["JavaScript", "React", "HTML", "CSS"],
      },
      {
        title: "Magari conosci anche",
        skills: ["Flutter + Dart", "React Native", "Swift", "Java", "C#"],
      },
    ],
  },
  // {
  //   slug: "ux-ui-designer",
  //   closed: true,
  //   area: "progettazione",
  //   role: "UX Designer",
  //   ctaMessage: "Ami disegnare?",
  //   ctaBtn: "Contattaci!",
  //   competences: [
  //     {
  //       title: "Competenze richieste",
  //       skills: [
  //         "Illustrazione digitale",
  //         "Motion graphics",
  //         "Conoscenza della lingua inglese",
  //       ],
  //     },
  //     {
  //       title: "Magari conosci anche",
  //       skills: ["Illustrazione digitale", "Motion graphics", "Animazione di interfacce"],
  //     },
  //   ],
  // },
]
