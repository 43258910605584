import Grid from "@material-ui/core/Grid"
import React from "react"
import Tilt from "react-tilt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/style.css"
import positions from "../positions"
import Page404 from "../pages/404"
import DetailLayoutPage from "./detail_layout"
import Swal from "sweetalert2"

export const HeartSymbol = () => (
  <span
    style={{
      color: "white",
      // color: "#e85322"
      backgroundColor: "#e85322",
      background: `radial-gradient(
    100% 500% at 100% 0%,
    #f49622 0%, 
    #e5142c 100%
  )`,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }}
    children="❤"
  />
)

export const createTargetMailtoProps = target => {
  const email = "jobs@mabiloft.com"
  let mailto = "mailto:" + email
  mailto += "?subject=" + encodeURI("Candidatura " + target.role)
  mailto +=
    "&body=" +
    encodeURI(
      // "... (Allegare portfolio e curriculum vitae)\n\n\n"
      "... (Raccontaci qualcosa su di te, e mandaci un curriculum 😉)\n\n\n"
    )
  return {
    href: mailto,
    rel: "noopener noreferrer",
    onClick: e => {
      const onBlur = () => {
        clearTimeout(timeout)
      }
      window.addEventListener("blur", onBlur)
      const timeout = setTimeout(() => {
        window.removeEventListener("blur", onBlur)
        // user has no mail client
        // alert("Manda il CV e raccontaci qualcosa su di te a\n\njobs@mabiloft.com")
        Swal.fire({
          title: "Manda il CV e raccontaci qualcosa su di te a",
          text: email,
          icon: "info",
          confirmButtonText: "Copia indirizzo email",
          confirmButtonColor: "#ff7e00",
        }).then(result => {
          if (result.isConfirmed) {
            copyToClipboard(email)
          }
        })
      }, 500)
    },
  }
}

export const CandidatiCard = ({ target, className }) => {
  return (
    <div className={className}>
      <a {...createTargetMailtoProps(target)}>
        <Tilt
          className="Tilt"
          options={{ max: 25, reverse: true }}
          style={{
            padding: "25px 17px",
            // borderRadius: 15,
            borderRadius: 20,
            backgroundColor: "white",
            textAlign: "center",
            transformStyle: "preserve-3d",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
            tranform:
              "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
          }}
        >
          <div
            style={{
              fontSize: 36,
              lineHeight: "36px",
              padding: 20,
              letterSpacing: 0.25,
              lineHeight: "100%",
              fontFamily: "Nunito Sans",
              fontWeight: "bold",
              color: "#1B203C",
            }}
            // children={target.ctaMessage}
            children="Vuoi unirti al team?"
          />

          <br />

          <div
            className="Tilt-inner btn-candidati"
            children={target.ctaBtn}
            style={
              target.closed && {
                background: "#eee",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
                color: "#444",
              }
            }
          />

          <br />

          {target.closed ? (
            <div
              children="( non disponibile )"
              style={{
                marginTop: 12,
                color: "#1B203C",
                opacity: 0.4,
                fontSize: 20,
                // fontWeight: "bold",
              }}
            />
          ) : (
            undefined
          )}
        </Tilt>
      </a>
    </div>
  )
}

const DetailPage = props => {
  const target = positions.find(x => x.slug === props.pageContext.slug)

  if (!target) return <Page404 />

  return <DetailLayoutPage position={target} />
}

export default DetailPage

function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea")
    textarea.textContent = text
    textarea.style.position = "fixed" // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand("copy") // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex)
      return prompt("Copy to clipboard: Ctrl+C, Enter", text)
    } finally {
      document.body.removeChild(textarea)
    }
  }
}
