import Grid from "@material-ui/core/Grid"
import React from "react"
import Tilt from "react-tilt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import positions from "../positions"
import { CandidatiCard, HeartSymbol, createTargetMailtoProps } from "./detail"
import "../styles/style.css"
import { formatMs } from "@material-ui/core/styles"

const hPadding = 24

const icons = {
  biceps: require("../images/biceps.png"),
  pencil: require("../images/pencil.png"),
  laptop: require("../images/laptop.png"),
  phone: require("../images/phone.png"),
}

const DetailLayoutPage = (props: { position: any }) => {
  const { position } = props

  return (
    <Layout>
      <SEO
        title={"WE ARE HIRING | " + position.role + " 👈"}
        description="Ruolo fondamentale nello svolgimento di progetti e nello sviluppo di applicativi web, piattaforme responsive, esperienze interattive e interfacce utente animate."
        image={null}
      />

      <Grid
        container
        direction="row"
        justify="space-between"
        className="page-container"
      >
        <Grid item xs>
          <a href="https://mabiloft.com/it/" target="_blank">
            <h3
              style={{
                paddingLeft: hPadding,
                color: "black",
                fontFamily: "font-family: 'Nunito Sans', sans-serif",
                pointerEvents: "none",
                msUserSelect: "none",
                KhtmlUserSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                userSelect: "none",
              }}
            >
              {/* mabiloft
          <span children="." style={{ color: "#e85322" }} /> */}

              <img
                src={require("../images/logotipo.svg")}
                height="24"
                style={{ margin: 0 }}
              />
            </h3>
          </a>

          <h1
            style={{
              color: "white",
              backgroundColor: "#e85322",
              background: `radial-gradient(
              100% 500% at 80% 0%,
              #f49622 30%, 
              #e5142c 100%
            )`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            children=""
          />

          <Paragraph
            style={{
              fontWeight: 700,
              marginTop: 50,
              marginBottom: 50,
              color: "#1B203C",
              // color: "white",
              fontSize: 44,
              lineHeight: 1.2,
              maxWidth: 670,
            }}
          >
            {/* Cerchiamo
          <span style={{ color: "#e85322" }} children=" persone " />
          che vogliono trasformare la propria
          <span style={{ color: "#e85322" }} children=" passione " />
          in un
          <span style={{ color: "#e85322" }} children=" lavoro" />. */}
            Cerchiamo una
            <span style={{ color: "#e85322" }} children=" persona " />
            con la
            <span style={{ color: "#e85322" }} children=" passione  " />
            per il
            <span style={{ color: "#e85322" }} children=" digitale" />.
          </Paragraph>

          <Paragraph>
            A Mabiloft realizziamo app e piattaforme web, prendendo molto sul
            serio la user experience: il nostro obiettivo è fare software
            mettendo al primo posto l’utente.
            <br />
            <br />
            Amiamo creare ottimi prodotti, ma soprattutto vogliamo farlo
            insieme. Ciò che si respira tra le nostre scrivanie è un legame che
            va oltre all’impegno lavorativo, prima di essere colleghi siamo
            amici e compagni. <b>Vuoi unirti al team?</b>
          </Paragraph>

          {/* <img
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
          }}
          src={require("../images/mabiloft behance cover.png")}
        /> */}

          <div
            style={{
              // height: "13vw",
              // zIndex: 2,
              position: "relative",
              whiteSpace: "nowrap",
              minHeight: 300,
              paddingBottom: 250,
              marginBottom: -250,
              marginTop: 70,
            }}
          >
            <div
              style={{
                lineHeight: "13vw",
                fontSize: "4vw",
                fontWeight: 700,
                color: "white",
                zIndex: 1,
                position: "relative",
              }}
              // children="Posizioni aperte"
            />

            <img
              style={{
                position: "absolute",
                left: -2000,
                right: -2000,
                top: 0,
                height: "100%",
                maxWidth: "none",
                margin: "auto",
                pointerEvents: "none",
              }}
              src={require("../images/mabiloft behance cover.png")}
            />
          </div>

          <PositionCard position={position} />

          <Paragraph style={{ margin: "70px 0" }}>
            Crediamo nel potere delle soft skills, sono quelle che ci hanno
            aiutato a creare qualcosa di unico e che cerchiamo in chi vuole far
            parte del nostro mondo. <br />
            Le conoscenze tecniche sono importanti per il nostro lavoro, ma
            crediamo che con lo spirito giusto possano essere apprese
            facilmente. <br />
            Entrando in Mabiloft, lavorerai con noi su progetti ricchi di sfide
            e soddisfazioni.{/* (Se vuoi vederne qualcuno, li trovi qui) */}
          </Paragraph>

          <ImageCard
            style={{ marginBottom: 100 }}
            src={require("../images/mabiloft_together.jpeg")}
            description={
              "Siamo sempre alla ricerca di persone che condividano i nostri valori fondamentali: una passione a tempo pieno per ciò che facciamo e la voglia di risolvere l’irrisolvibile."
            }
          />

          {position.bodyInfo ? (
            <DottedParagraph
              style={{ marginBottom: 64 }}
              title={position.bodyInfo.title}
              description={position.bodyInfo.description}
              items={position.bodyInfo.items}
              icon={
                position.bodyInfo.icon ? (
                  <img
                    width={26}
                    src={icons[position.bodyInfo.icon]}
                    style={{ margin: 0 }}
                  />
                ) : null
              }
            />
          ) : null}

          <DottedParagraph
            title="Le cose che ci stanno a cuore"
            icon={<HeartSymbol />}
            items={[
              "Lavoro di squadra come spinta di tutto, la vittoria del singolo è la vittoria del gruppo. Un team non può essere fatto di solisti.",
              "Proattività, mettersi in gioco e cercare soluzioni è la migliore delle qualità.",
              "Giusta attitudine, ci piacciono le persone che cercano sempre qualcosa di nuovo da imparare e che non si fermano in superficie.",
              "Visione sul futuro: vogliamo costruire, tanto!",
              "Feedback, per noi è importante darne e riceverne, indipendentemente dal fatto che siano positivi o negativi.",
              "Lavoro in presenza, passare bei momenti insieme è stato fondamentale per creare il legame che c’è a Mabiloft.",
              // "Ottima conoscenza di meme (conosciamo a memoria gli sketch di Aldo, Giovanni e Giacomo e le puntate più divertenti di Masterchef)",
            ]}
          />

          <ImageCard
            src={require("../images/mabiloft_picture.gif")}
            style={{ margin: "64px 0" }}
            // description="In realtà siamo più persone ora, ma abbiamo bisogno di te per rifare le foto"
          />

          <DottedParagraph
            style={{ marginBottom: 64 }}
            // title="Cosa troverai qui"
            title="Cosa ti aspetta"
            icon={<img width={26} src={icons.biceps} style={{ margin: 0 }} />}
            description="Se ti rivedi in tutto ciò, potresti essere la persona che stiamo cercando. Abbiamo molto da offrirti, tra cui:"
            items={[
              "Un ambiente inclusivo e stimolante, dove gli unici limiti alla tua crescita saranno quelli che ti porrai tu. Le tue idee verranno sempre ascoltate e valutate, indipendentemente dal tempo che hai passato con noi. ",
              "Vari gadget brandizzati Mabiloft, tra cui una tazza pronta per essere riempita con tutto il caffè che potresti volere (ma anche tè e ginseng, se preferisci)",
              "Giornate di lavoro cariche di soddisfazione, ciò che farai con noi porterà innovazione e miglioramento nella vita delle persone ",
              "Serate di gruppo caratterizzate da pizza e confronti sul reale significato della vita",
              "Tornei di ping pong e calcio balilla estremamente competitivi",
              'Postazione comoda e scrivania tutta tua, macBook e monitor 27" 2k, ci piace lavorare bene e comodamente',
              "Dopo un periodo di prova iniziale, ti offriamo un lavoro a tempo pieno nel nostro ufficio a Padova",
              "Ci piace pranzare insieme, per questo il pranzo lo offriamo noi! (P.S. abbiamo anche l'opzione vegetariana)",
            ]}
          />

          <CandidatiCard target={position} className="" />

          <div style={{ height: 200 }} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default DetailLayoutPage

const Paragraph = props => (
  <p
    {...props}
    style={{
      // marginTop: 50,
      // marginBottom: 50,
      color: "#1B203C",
      fontSize: 18,
      // fontSize: 20,s
      lineHeight: 1.33,
      letterSpacing: 0.5,
      // maxWidth: 600,
      // maxWidth: 700,
      padding: "0 " + hPadding + "px",
      whiteSpace: "pre-line",
      ...(props.style || {}),
    }}
  />
)

const DottedParagraph = props => (
  <div style={props.style}>
    <div
      style={{
        fontSize: 26,
        lineHeight: "26px",
        padding: `0 ${hPadding}px`,
        fontWeight: "bold",
        letterSpacing: 0.25,
        marginBottom: 16,
      }}
    >
      {props.title} {props.icon}
    </div>

    {props.description ? <Paragraph children={props.description} /> : null}

    <ul style={{ marginTop: 10, lineHeight: 1, padding: `0 ${hPadding}px` }}>
      {props.items.map((item, index) => (
        <li
          key={index}
          children={item}
          style={{
            fontSize: 18,
            lineHeight: "22px",
            // color: "#1B203C",
            // fontSize: "0.7rem",
            // opacity: 0.8,
            // letterSpacing: "1px",
            // marginTop: -4,
            // marginBottom: 8,
          }}
        />
      ))}
    </ul>
  </div>
)

const ImageCard = props => (
  <div style={props.style}>
    <img
      src={props.src}
      style={{
        width: "100%",

        borderRadius: 20,
        boxShadow:
          "-24px 18px 36px -8px rgba(24, 74, 123, 0.12), inset 0px 0px 36px rgba(255, 255, 255, 0.36)",
      }}
    />
    {props.description ? (
      <p
        children={props.description}
        style={{
          marginTop: 0,
          padding: `0 ${hPadding}px`,
          fontSize: 16,
          letterSpacing: 0.4,
          lineHeight: "19px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      />
    ) : null}
  </div>
)

const PositionCard = props => {
  const target = props.position
  const isClickable = false

  const comp = (
    <Tilt
      className="Tilt"
      options={{ max: 10, reverse: true }}
      style={{
        // padding: hPadding,
        // paddingTop: hPadding * 2,
        marginBottom: 20,
        borderRadius: 20,
        backgroundColor: "white",
        // textAlign: "center",
        transformStyle: "preserve-3d",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        transform:
          "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
      }}
    >
      <Grid
        style={{
          position: "relative",
          opacity: 1,
          // padding: hPadding,
          // paddingTop: hPadding * 2,
          // marginBottom: 23,
          padding: hPadding * 2,
          // paddingBottom: 0,

          // background: "#FFFFFF",
          // boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          // borderRadius: 20,

          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            textTransform: "uppercase",
            opacity: 0.3,
            marginBottom: 8,
            fontSize: 15,
            fontWeight: "bold",
          }}
          // children={target.area}
          children={"Posizione " + (target.closed ? "chiusa" : "aperta")}
        />

        <h2
          children={(target.roleH1 || target.role).replace("_", " ")}
          style={
            target.closed
              ? {
                  color: "#1B203C",
                }
              : {
                  backgroundColor: "#e85322",
                  background: `radial-gradient(
              100% 500% at 80% 0%,
              #f49622 30%,
              #e5142c 100%
            )`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }
          }
        />
        {target.competences.map((item, index) => (
          <span key={index}>
            <b
              children={item.title}
              style={{ letterSpacing: 1, color: "#1B203C" }}
            />
            <ul style={{ marginTop: 10, lineHeight: 1 }}>
              {item.skills.map((skill, i) => {
                if (skill === "OR") {
                  return (
                    <div
                      key={index + "_" + i}
                      children="(oppure)"
                      style={{
                        color: "#1B203C",
                        fontSize: "0.7rem",
                        opacity: 0.8,
                        letterSpacing: "1px",
                        marginTop: -4,
                        marginBottom: 8,
                      }}
                    />
                  )
                }

                return <li children={skill} key={i} />
              })}
            </ul>
          </span>
        ))}

        {target.moreInfo ? (
          <div
            children={target.moreInfo}
            style={{ marginTop: 0, marginBottom: 12, opacity: 0.8 }}
          />
        ) : null}

        <a {...createTargetMailtoProps(target)} style={{ alignSelf: "center" }}>
          <div
            className="Tilt-inner btn-candidati"
            children={target.ctaBtn}
            style={
              !target.closed
                ? { alignSelf: "center" }
                : {
                    background: "#eee",
                    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
                    color: "#444",
                  }
            }
          />
          <br />
          {target.closed ? (
            <div
              children="( non disponibile )"
              style={{
                marginTop: 12,
                color: "#1B203C",
                opacity: 0.4,
                fontSize: 20,
                // fontWeight: "bold",
              }}
            />
          ) : (
            undefined
          )}
        </a>

        {/* <div
          style={{
            display: "inline-block",
            fontWeight: "bold",
            fontSize: 20,
            color: target.closed ? "#333" : "#e85322",
            padding: 12,
            margin: -12,
            // background: `radial-gradient(
            //   100% 500% at 80% 0%,
            //   #f49622 30%,
            //   #e5142c 100%
            // )`,
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent",
          }}
          children={target.closed ? "Non disponibile" : "Scopri di più"}
        /> */}
        {/* <ArrowForwardIcon /> */}
      </Grid>
    </Tilt>
  )

  if (isClickable) return <a href={"/" + target.slug} children={comp} />

  return comp
}
